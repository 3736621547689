<script setup>
import { inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useThermStore } from '../store/therm.store';
import { useOnGenerateCSVClicked } from '~/therm/composables/therm-export-csv';
import { useThermPdfExporter } from '~/therm/composables/therm-pdf-exporter.js';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useThermHelperComposable } from '~/therm/composables/helper-composable.js';
import useEmitter from '~/common/composables/useEmitter';
import ThermSettingsModal from '~/therm/components/therm-settings-modal.vue';
import { useFormsStore } from '~/forms/store/forms.store';
import ThermExportPopup from '~/therm/components/therm-export-popup.vue';
import MbTilesPopup from '~/terra/components/mb-tiles-popup.vue';

const $t = inject('$t');
const $toast = inject('$toast');
const emitter = useEmitter();
const auth_store = useAuthStore();
const therm_store = useThermStore();
const forms_store = useFormsStore('therm_forms_store');
const route = useRoute();
const router = useRouter();
const { flyToAssociatedFeature } = useThermHelperComposable();
const export_disabled = ref(false);
const selected_features = ref(null);

const state = reactive({
  export_as: '',
  is_exporting: false,
  is_pdf_export_complete: false,
  pdf_options: {},
  current_export_file_index: 1,
  total_files_to_export: 1,
});

const therm_settings_popup = useModal({
  component: ThermSettingsModal,
  attrs: {
    onClose() {
      therm_settings_popup.close();
    },
  },
});

const therm_export_popup = useModal({
  component: ThermExportPopup,
  attrs: {
    onClose() {
      therm_export_popup.close();
    },
  },
});
const mb_tiles_popup = useModal({
  component: MbTilesPopup,
  attrs: {
    type: 'therm',
    onClose() {
      mb_tiles_popup.close();
    },
  },
});
const header_tabs = computed(() => ([
  {
    uid: 'therm',
    label: 'Map',
    to: { name: 'therm', params: { ...route.params } },
  },
  {
    uid: 'therm-defects',
    label: 'Defects',
    to: { name: 'therm-defects', params: { ...route.params } },
  }, {
    uid: therm_store.use_tasks ? 'therm-reports' : 'therm-dashboard',
    label: 'Reports',
    to: { name: therm_store.use_tasks ? 'therm-reports' : 'therm-dashboard', params: { ...route.params } },
  },
]));

onMounted(async () => {
  await getData();
  therm_store.is_data_loaded = true;
});

watch(() => therm_store.polygon, async (polygon) => {
  if (polygon)
    await forms_store.set_forms({
      query: {
        stage: 'THERM',
        status: 'published',
        polygon,
        is_child: true,
        all_access: true,
        asset_uid: route.params.asset_id,
      },
    });
});
watch(() => therm_store.show_request_mbtiles_popup?.requested_reports, (val) => {
  if (therm_store.show_request_mbtiles_popup?.resolved_all_requests && Object.keys(therm_store.show_request_mbtiles_popup?.requested_reports || {}).length)
    mb_tiles_popup.open();
},
{
  deep: true,
});
onBeforeUnmount(() => {
  therm_store.reset_store();
});

async function getData() {
  try {
    therm_store.loader_enabled = true;
    therm_store.set_ftg_and_update_features_styles({
      uid: route.params.id,
      update_features_styles: false,
    });

    await therm_store.set_container({
      uid: route.params.id,
      initial_load: true,
    });
    await therm_store.get_status_configs(route.params.id);
    if (route.query?.metadata)
      await flyToAssociatedFeature('therm');

    therm_store.loader_enabled = false;
  }
  catch (err) {
    therm_store.loader_enabled = false;
    if (route.query?.metadata)
      $toast({
        title: $t('Location not found'),
        text: $t('Can not navigate to the location. You don\'t have access, or the location is no longer available'),
        type: 'warning',
      });
  }
}

function resizeMap() {
  if (therm_store.map)
    setTimeout(() => {
      therm_store.map.resize();
    }, 250);
}

function openSettingsModal() {
  const options = {
    use_tasks: therm_store.use_tasks,
    show_defect_status_icons: therm_store.show_defect_status_icons,
    disable_use_tasks: !auth_store.check_split('therm_tasks_config'),
  };
  therm_settings_popup.patchOptions({
    attrs: {
      ...options,
      on_save: async (formData, { data }) => {
        if (options.use_tasks !== data.use_tasks)
          await therm_store.updateUseTasks({ useTasks: data.use_tasks });
        therm_store.show_defect_status_icons = data.use_tasks ? true : data.show_defect_status_icons;
        therm_settings_popup.close();
      },
    },
  });
  therm_settings_popup.open();
}

function openExportPopup(export_type = '') {
  therm_export_popup.patchOptions({
    attrs: {
      export_type,
      onExport({ export_as, pdf_options }) {
        state.export_as = export_as;
        state.pdf_options = pdf_options;
      },
    },
  });
  therm_export_popup.open();
}

function isExporting() {
  return state.is_exporting;
}

async function onGenerateCSVClicked(e, issues = []) {
  state.is_exporting = true;
  await useOnGenerateCSVClicked(issues, route, isExporting);
}

async function onGeneratePDFClicked() {
  state.is_pdf_export_complete = false;
  state.is_exporting = true;
  const therm_pdf_exporter = useThermPdfExporter((payload) => {
    state.current_export_file_index = payload.current_export_file_index;
    state.total_files_to_export = payload.total_files_to_export;
  }, isExporting, state.pdf_options);
  await therm_pdf_exporter.exportPDF(selected_features.value);
}

function onExportClose() {
  state.is_exporting = false;
  state.export_as = '';
  selected_features.value = null;
}

emitter.on('therm_pdf_export_action', async (features) => {
  selected_features.value = features;
  openExportPopup('PDF');
});

onBeforeUnmount(() => {
  emitter.off('therm_pdf_export_action');
});

watch(route.name, (val) => {
  if (val === 'therm')
    resizeMap();
});
watch(() => route.params.asset_id, (val) => {
  if (val)
    router.push({ name: 'maps-list', params: { type: 'maps-list', asset_id: val } });
});
</script>

<template>
  <div>
    <HawkExportToast v-if="state.export_as === 'CSV'" :submit="onGenerateCSVClicked" progress_text="Exporting to CSV" completed_text="Exported CSV" @cancel="onExportClose" @close="onExportClose" />
    <HawkExportToast v-if="state.export_as === 'PDF'" :submit="onGeneratePDFClicked" @cancel="onExportClose" @complete="onExportClose" @close="onExportClose">
      <template v-if="!state.is_pdf_export_complete" #title>
        {{ $t('Exporting PDF') }}<span v-if="state.total_files_to_export > 1">: {{ state.current_export_file_index }}/{{ state.total_files_to_export }}</span>
      </template>
      <template v-else #title>
        {{ $t('Exported PDF') }}
      </template>
    </HawkExportToast>
    <div v-if="therm_store.loader_enabled" class="absolute w-full h-[calc(100vh-70px)] z-50 bg-gray-100 opacity-[0.9] flex justify-center items-center">
      <HawkLoader />
    </div>
    <div class="therm-routes h-[68px] flex flex-col justify-center ">
      <HawkPageHeader :title="$t('Therm')" class="text-gray-900  font-semibold text-lg">
        <template #left>
          <HawkPageHeaderTabs :tabs="header_tabs" :active_item="route.name" />
        </template>
        <template #right>
          <div class="flex items-center">
            <HawkButton class="font-semibold text-gray-700 text-sm" type="outlined" @click="openExportPopup()">
              {{ $t('Export') }}
            </HawkButton>
            <HawkButton v-if="auth_store.is_internal_user" type="outlined" icon size="sm" class="ml-2" @click="openSettingsModal">
              <IconHawkSettingsOne />
            </HawkButton>
          </div>
        </template>
      </HawkPageHeader>
    </div>
  </div>
  <div />
  <div class="therm flex m-0 h-[calc(100vh-133px)]">
    <ThermLeftSidebar
      v-if="!['therm-compare', 'therm-dashboard'].includes(route.name)"
      class="flex-grow p-0"
    />
    <keep-alive v-if="route.name !== 'therm-compare'">
      <router-view class="flex-grow p-0 relative" />
    </keep-alive>
    <router-view v-else class="flex-grow p-0 relative" />
  </div>
</template>

<style lang="scss">
  #therm-map {
  background: rgb(215, 215, 215);
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}
</style>
