<script setup>
import { useRoute, useRouter } from 'vue-router';

import { Validator } from '@vueform/vueform';
import { useTransmittalWorkflowConfiguration } from '~/dms/composables/useTransmittalWorkflow.js';
import { useTransmittalWorkflowStore } from '~/dms/store/transmittal-workflow.store';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  modelValue: {
    type: Object,
  },
  form$: {
    type: Object,
  },
  transmittal: {
    type: Object,
  },
  default_statuses: {
    type: Object,
  },
  is_sub_workflow: {
    type: Boolean,
  },
  is_settings: {
    type: Boolean,

  },
});

const emits = defineEmits(['update:modelValue', 'update:default_statuses']);

const route = useRoute();
const router = useRouter();
const { outcome_strategy_list, get_default_statuses, getSelectedWorkflow } = useTransmittalWorkflowConfiguration(props);
const transmittal_workflow_store = useTransmittalWorkflowStore();
const transmittal_permissions = useTransmittalPermissions();
const dms_settings_store = useDMSSettingsStore('transmittals');
const auth_store = useAuthStore();
const $t = inject('$t');
const share_data = ref((props.transmittal?.members || []).map(member => ({ ...member, access: member.role })));
const selected_workflow = ref(getSelectedWorkflow(props.transmittal.workflow ? { workflow: props.transmittal.workflow } : null));
const workflows = computed(() => transmittal_workflow_store.workflows);
const ignored_members = ref([]);
getIgnoredUsers();

const show_info = ref(null);

const submitter_access_levels = props.is_sub_workflow ? [] : [{ name: 'submitter', label: $t('Submitter'), description: $t('Members whom you want to request the documents') }];

const default_access_levels = ref([
  ...submitter_access_levels,
  { name: 'reviewer', label: $t('Reviewer'), description: $t('Members whom you want to review the documents') },
  { name: 'approver', label: $t('Approver'), description: $t('Members whom you want to review/approve documents along with you') },
  { name: 'information', label: $t('Information/CC'), description: $t('Members whom you want to keep in loop') },
]);

const access_levels = ref(default_access_levels.value);

const external_user_access_levels = ref(submitter_access_levels);

async function workflowChanged(workflow_uid) {
  const workflow = workflows.value.find(workflow => workflow.uid === workflow_uid);
  share_data.value = [];
  selected_workflow.value = workflow;
  getIgnoredUsers();
  emits('update:default_statuses', get_default_statuses(workflow?.workflow?.status_map));
  if (workflow_uid) {
    access_levels.value = [
      ...submitter_access_levels,
      { name: 'information', label: $t('Information/CC'), description: 'Receives updates on document changes' },
    ];
    const all_sub_steps = Object.values(workflow?.workflow?.steps || {}).map(step => step?.sub_steps || []).flat();
    if (all_sub_steps)
      all_sub_steps.forEach((sub_step) => {
        if (sub_step?.members?.length > 0)
          sub_step.members = sub_step.members.filter(member => member !== auth_store.logged_in_user_details?.user_id);
      });
    selected_workflow.value = getSelectedWorkflow(workflow);
  }
  else {
    access_levels.value = default_access_levels.value;
    selected_workflow.value = null;
  }
  emits('update:modelValue', selected_workflow.value);
}

function toggleStatus({ index, status }) {
  const default_statuses = props.default_statuses;
  default_statuses[index].active = status;
  if (props.modelValue?.workflow) {
    const workflow = props.modelValue.workflow;
    workflow.status_map[index].active = status;
  }
}

function loadData(workflow_ref) {
  workflow_ref.load(props.modelValue.workflow, true);
}

const minimum_approval_validator = class extends Validator {
  get message() {
    return 'The value should be less than the number of reviewers';
  }

  check(value) {
    const reviewers = share_data.value.filter(member => member.access === 'reviewer');
    return reviewers.length >= +value;
  }
};

const has_placeholder_document = computed(() => !!Object.values(props.form$.data.documents).find(document => !document.service_object?.key));

const minimum_members_validator = class extends Validator {
  get message() {
    if (has_placeholder_document.value)
      return 'Need at least one submitter';
    else
      return 'Needs at least one reviewer or approver';
  }

  check() {
    if (has_placeholder_document.value)
      return share_data.value.filter(member => member.access === 'submitter').length > 0;
    else if (selected_workflow.value)
      return true;
    else
      return share_data.value.filter(member => member.access === 'reviewer' || member.access === 'approver').length > 0;
  }
};

function getIgnoredUsers() {
  ignored_members.value = (selected_workflow.value && selected_workflow.value?.workflow?.steps && Object.values(selected_workflow.value?.workflow?.steps || {})?.map(step => step?.members)?.flat()) || [];
}

const has_approver = computed(() => !!share_data.value.find(member => member.access === 'approver'));
const has_reviewer = computed(() => !!share_data.value.find(member => member.access === 'reviewer'));
const has_submitter = computed(() => !!share_data.value.find(member => member.access === 'submitter'));

function get_share_data(data) {
  share_data.value = data?.users;

  if (props.form$?.elements$) {
    props.form$.elements$.members.update(data?.users);
    props.form$.elements$.members.validate();
  }
  const default_statuses = props.default_statuses;
  if (has_submitter.value) {
    default_statuses[4].disabled = true;
    default_statuses[4].document_status = undefined;
    default_statuses[5].disabled = true;
    default_statuses[5].document_status = undefined;
  }
  else {
    default_statuses[4].disabled = false;
    default_statuses[5].disabled = false;
  }
}

if (share_data.value.length > 0)
  get_share_data({ users: share_data.value });
</script>

<template>
  <div class="col-span-12">
    <GroupElement name="recipient_details">
      <div class="max-w-[700px] pl-4 grid gap-5 col-span-12">
        <div>
          <div class="text-md font-semibold text-gray-900">
            {{ $t('Workflow') }}
          </div>
          <div class="text-xs w-[780px]">
            {{ $t('Select a template to automate reviewal process, or leave this field empty if you prefer not to use a workflow.') }}
            <span v-if="transmittal_permissions.checkTransmittalPermission({ permission: 'workflow_new_cta' })" class="font-semibold text-primary-600 cursor-pointer" @click="router.push({ name: 'dms-workflow-templates', params: { asset_id: route.params.asset_id }, query: { create: true } })">
              {{ $t('Create a new workflow') }}
            </span>
          </div>
          <SelectElement
            name="workflow_template"
            placeholder="No workflow"
            class="mt-3"
            :default="transmittal?.workflow_template"
            :items="workflows" :can-clear="true" label-prop="name" value-prop="uid" :native="false"
            @change="workflowChanged"
          />
        </div>
        <div>
          <div class="text-md font-semibold text-gray-900">
            {{ $t('Members') }}
          </div>
          <div class="text-xs">
            {{ $t('Choose members whom you want request or review and approve the workflow.') }}
          </div>
        </div>
      </div>
    </GroupElement>
    <hawk-share v-show="form$?.tabs$?.current$?.name === 'recipients' || is_settings" :key="selected_workflow" :members="share_data" :ignored_members="ignored_members" :access_levels="access_levels" :external_user_access_levels="external_user_access_levels" class="ml-4 mt-4 mb-4 pb-4 border-b" :get_share_data="get_share_data" :has_teams="false">
      <template #after-input>
        <div v-if="form$?.elements$.members?.invalid" class="form-color-danger block flex !text-red-700 form-text-small-sm mt-0.5">
          {{ form$?.elements$.members.error }}
        </div>
      </template>
    </hawk-share>
    <HiddenElement :default="share_data" :meta="true" name="members" :rules="[minimum_members_validator]" />

    <div class="max-w-[700px] pl-4 grid gap-5 col-span-12 ">
      <DateTimeElement
        :conditions="[
          function(form$) {
            return has_submitter
          },
        ]"
        :default="transmittal?.due_date"
        name="submission_due_date"
        :label="$t('Submission due date')"
        :description="$t('Choose a date by which you expect the document submission')"
        :placeholder="$t('Select date')"
      />
      <TextElement
        :conditions="[
          function(form$) {
            return !selected_workflow && (has_reviewer || has_approver)
          },
        ]"
        name="reviewal_duration"
        :label="$t('Duration')"
        :description="!has_submitter ? $t('Number of days for reviewers/approvers to respond') : $t('Number of days for reviewers/approvers to respond after the submission')"
        :suffix="$t('days')"
        rules="required"
      />
      <RadiogroupElement
        :conditions="[
          function(form$) {
            return has_reviewer && !has_approver
          },
        ]"
        name="review_condition"
        :label="$t('Condition')"
        :items="[
          { value: 'AND', label: 'Require all members to approve' },
          { value: 'OR', label: 'Require any members to approve' },
        ]"
        rules="required"
      >
        <template #info>
          <IconHawkInfoCircle class="ml-1 w-4 h-4 mt-0.5 cursor-pointer" @click="show_info = 'review_condition'" />
        </template>
      </RadiogroupElement>
      <TextElement
        input-type="number"
        :rules="[
          'required',
          'numeric',
          'min:1',
          minimum_approval_validator,
        ]"
        :default="transmittal?.minimum_approvals"
        name="minimum_approvals"
        :description="$t('Enter minimum number of responses required for approval')"
        :attrs="{ min: '1' }"
        :label="$t('Minimum approval')"
        :conditions="[
          ['review_condition', ['OR']],
        ]"
      />
      <CheckboxgroupElement
        :conditions="[
          function(form$) {
            return !selected_workflow && (has_reviewer || has_submitter)
          },
        ]"
        name="feedback_visibility"
        :native="false"
        :items="[
          ...(has_reviewer ? [{ value: 'reviewer', label: 'Reviewers' }] : []),
          ...(has_submitter ? [{ value: 'submitter', label: 'Submitter' }] : []),
        ]"
        :default="[]"
        label="View feedback"
        description="Allow members to view the feedback, status, annotations of other members."
      />
      <SelectElement
        :conditions="[
          function(form$) {
            return has_submitter
          },
        ]"
        :label="$t('Submission entry status')"
        name="submission_entry_status"
        :native="false" :can-deselect="false" :can-clear="true"
        :add-classes="{
          SelectElement: {
            select: {
              dropdown: ['scrollbar'],
            },
          },
        }"
        :description="$t('Select a status to set to the document on starting the submission flow.')"
        :items="dms_settings_store.active_document_status"
        label-prop="name"
        value-prop="slug"
      />
      <ToggleElement
        :conditions="[
          function(form$) {
            return (selected_workflow || has_reviewer) && has_submitter
          },
        ]"
        :description="$t('Reset due dates for reviewers/approvers on resubmission')"
        :label="$t('Reset due dates')"
        name="reset_due_dates"
      />
      <SelectElement
        :conditions="[
          function(form$) {
            return has_reviewer || has_approver
          },
        ]"
        :label="$t('Reviewal entry status')"
        name="reviewal_entry_status"
        :native="false" :can-deselect="false" :can-clear="true"
        :add-classes="{
          SelectElement: {
            select: {
              dropdown: ['scrollbar'],
            },
          },
        }"
        :description="$t('Select a status to set to the document on starting the reviewal flow.')"
        :items="dms_settings_store.active_document_status"
        label-prop="name"
        value-prop="slug"
      />
    </div>
    <transmittal-workflow-detail-workflow v-if="modelValue" :key="modelValue" class="px-4" :published="true" :steps="modelValue?.workflow?.steps" :outcome_strategy_list="outcome_strategy_list" :default_statuses="default_statuses" @toggle-active="toggleStatus" @mounted="loadData" />
    <ObjectElement
      v-if="!selected_workflow"
      class="pt-4 mt-2"
      name="review_status_map"
    >
      <div class="col-span-12 px-4">
        <div class="text-lg font-semibold">
          {{ $t('Map review statuses') }}
        </div>
        <div class="text-xs">
          {{ $t('Choose the initial document status') }}
        </div>
        <div class="mt-3">
          <transmittal-workflow-status-map
            :default_statuses="default_statuses"
            @toggle-active="toggleStatus"
          />
        </div>
      </div>
    </ObjectElement>
  </div>
  <create-transmittal-info-approval-sidebar :show_info="show_info" @close="show_info = null" />
  <create-transmittal-info-condition-sidebar :show_info="show_info" @close="show_info = null" />
</template>
